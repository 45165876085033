import { Link } from 'react-router-dom';
import FormLayout from '../../layouts/FormLayout';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const LandingPage = () => {
  // const image = process.env.PUBLIC_URL;
  const IconData = [
    {
      icon: `/Icon/IRP.svg`,
      icon2: '/Cha/ChaRP.svg',
      label: 'REPORTER',
      description:
        'Reporter is person who have responsible in making news articles and broadcasting some event reports.',
      Gen15: [
        {
          nama: 'A. A. Inten Pramitriyani',
          prodi: 'STr Game Technology',
          foto: '/Member/RP_INTEN.jpg',
        },
      ],
      Gen16: [
        {
          nama: "Rifda Qurrotul'Ain",
          prodi: 'STr Applied Data Science',
          foto: '/Member/RP_RIFDA.jpg',
        },
        {
          nama: 'Suci Rindya K.',
          prodi: 'STr Energy Generation System',
          foto: '/Member/RP_SUCI.jpg',
        },
      ],
      Gen17: [
        {
          nama: 'Arman Jaya',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/RP_ARMAN.jpg',
        },
        {
          nama: 'Erina Nur M. J.',
          prodi: 'STr Applied Data Science',
          foto: '/Member/RP_ERINA.jpg',
        },
        {
          nama: 'Harun Budiseto',
          prodi: 'STr Multimedia Engineering Technology',
          foto: '/Member/RP_HARUN.jpg',
        },
        {
          nama: 'Maya Listyani',
          prodi: 'STr Multimedia Engineering Technology',
          foto: '/Member/RP_MAYA.jpg',
        },
      ],
      Gen18: [
        {
          nama: 'Annisa Rafa F. R.',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/RP_Rafa.jpg',
        },
        {
          nama: 'Hasnah Aulia Z.',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/RP_Hasnah.jpg',
        },
        {
          nama: 'Biyan Daniswara',
          prodi: 'STr Applied Data Science',
          foto: '/Member/RP_Biyan.jpg',
        },
      ],
    },
    {
      icon: '/Icon/IFG.svg',
      icon2: '/Cha/ChaFG.svg',
      label: 'PHOTOGRAPHER',
      description:
        'Photographer is person who have responsible in take a documentation photo at some event.',
      Gen15: [
        {
          nama: 'Bunga Adinda',
          prodi: 'STr Game Technology',
          foto: '/Member/FG_BUNGA.jpg',
        },
        {
          nama: 'M. Izzudin Fasya',
          prodi: 'STr Informatics Engineering',
          foto: '/Member/FG_FASYA.jpg',
        },
      ],
      Gen16: [
        {
          nama: 'Karina Sovia S.',
          prodi: 'D3 Telecommunications Engineering',
          foto: '/Member/FG_KARINA.jpg',
        },
        {
          nama: 'Lily Ananta S.',
          prodi: 'STr Telecommunications Engineering',
          foto: '/Member/FG_LILY.jpg',
        },
        {
          nama: 'Johan Muhammad I.',
          prodi: 'D3 Industrial Electrical Engineering',
          foto: '/Member/FG_JOHAN.jpg',
        },
        {
          nama: 'Miftahul Rizqi',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/FG_RIZKY.jpg',
        },
      ],
      Gen17: [
        {
          nama: 'Ashilliya Atsmara Z.',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/FG_ASHIL.jpg',
        },
        {
          nama: 'Ikhsan Nur A.',
          prodi: 'STr Multimedia Engineering Technology',
          foto: '/Member/FG_IKHSAN.jpg',
        },
        {
          nama: 'Muhammad Soleh.',
          prodi: 'STr Multimedia Engineering Technology',
          foto: '/Member/FG_SOLEH.jpg',
        },
      ],
      Gen18: [
        {
          nama: 'Salwa Fadhila R.',
          prodi: 'STr Informatics Engineering',
          foto: '/Member/FG_Salwa.jpg',
        },
        {
          nama: 'Harits Dwi R.',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/FG_Harits.jpg',
        },
        {
          nama: 'Bintang Alifananda',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/FG_Bintang.jpg',
        },
      ],
    },
    {
      icon: '/Icon/IVG.svg',
      icon2: '/Cha/ChaVG.svg',
      label: 'VIDEOGRAPHER',
      description:
        'Videographer is person who have responsible in making small scale journalism video, start from take a video, video editing, and audio editing.',
      Gen14: [
        {
          nama: 'Dyaz Nurul A. I.',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/VG_DYAZ.jpg',
        },
      ],
      Gen15: [
        {
          nama: 'Fariz Bagaskoro M.',
          prodi: 'STr Telecommunications Engineering',
          foto: '/Member/VG_FARIZ.jpg',
        },
      ],
      Gen16: [
        {
          nama: 'Iqbal Dhiyaudin P.',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/VG_IQBAL.jpg',
        },
        {
          nama: 'Burhanuddin Ahmad H.',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/VG_BURHAN.jpg',
        },
      ],
      Gen17: [
        {
          nama: 'Alvira Nilam A. P.',
          prodi: 'STr Energy Generation System',
          foto: '/Member/VG_ALVIRA.jpg',
        },
        {
          nama: 'Elham Cahya R.',
          prodi: 'D3 Telecommunications Engineering',
          foto: '/Member/VG_ELHAM.jpg',
        },
        {
          nama: 'Fuad Zaki N.',
          prodi: 'STr Multimedia Engineering Technology',
          foto: '/Member/VG_FUAD.jpg',
        },
        {
          nama: 'Gerardus M. Angger',
          prodi: 'D4 Internet Engineering Technology',
          foto: '/Member/VG_ANGGER.jpg',
        },
      ],
      Gen18: [
        {
          nama: 'Agri Dwika A.',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/VG_Agri.jpg',
        },
        {
          nama: 'Aliy Nur R.',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/VG_Aliy.jpg',
        },
        {
          nama: 'Nickolas Yudistira',
          prodi: 'STr Informatics Engineering',
          foto: '/Member/VG_Nicko.jpg',
        },
        {
          nama: 'Ezar Muhammad I.',
          prodi: 'STr Multimedia Engineering Technology',
          foto: '/Member/VG_Ezar.jpg',
        },
      ],
    },
    {
      icon: '/Icon/IDG.svg',
      icon2: '/Cha/ChaDG.svg',
      label: 'GRAPHIC DESIGNER',
      description:
        'Graphic designer is person who have responsible in making visualization an information with computer software.',
      Gen15: [
        {
          nama: 'Ryan Lazuardy',
          prodi: 'STr Game Technology',
          foto: '/Member/DG_RYAN.jpg',
        },
      ],
      Gen16: [
        {
          nama: 'Diva Farikha S.',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/DG_DIVA.jpg',
        },
        {
          nama: 'M. Rasyid Maulana',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/DG_RASYID.jpg',
        },
        {
          nama: 'Satrio Yoga P.',
          prodi: 'D3 Informatics Engineering',
          foto: '/Member/DG_YOGA.jpg',
        },
      ],
      Gen17: [
        {
          nama: 'Afsun Filosof.',
          prodi: 'D3 Telecommunications Engineering ',
          foto: '/Member/DG_AFSUN.jpg',
        },
        {
          nama: 'Shabrina Putri R.',
          prodi: 'STr Multimedia Engineering Technology',
          foto: '/Member/DG_SHABRINA.jpg',
        },
      ],
      Gen18: [
        {
          nama: 'Akhmad Tegar R.',
          prodi: 'STr Game Technology',
          foto: '/Member/DG_Tegar.jpg',
        },
        {
          nama: 'Callista Laylie S.',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/DG_Callista.jpg',
        },
        {
          nama: 'Fajrina Azzahrah B.',
          prodi: 'D3 Multimedia Broadcasting',
          foto: '/Member/DG_Ririn.jpg',
        },
        {
          nama: 'Falih Aryadhika E.',
          prodi: 'STr Game Technology',
          foto: '/Member/DG_Falih.jpg',
        },
      ],
    },
    {
      icon: '/Icon/IWM.svg',
      icon2: '/Cha/ChaWM.svg',
      label: 'WEBMASTER',
      description:
        'Webmaster is person who have responsible in technical network of campus and campus website development.',
      Gen15: [
        {
          nama: 'Santi',
          prodi: 'STr Informatics Engineering',
          foto: '/Member/WM_SANTI.jpg',
        },
      ],
      Gen16: [
        {
          nama: 'M. Althaf Firdaus',
          prodi: 'STr Energy Generation System',
          foto: '/Member/WM_ALTHAF.jpg',
        },
        {
          nama: 'Nabulatulhawa',
          prodi: 'D3 Informatics Engineering',
          foto: '/Member/WM_HAWA.jpg',
        },
        {
          nama: 'A. M. Khoirul Fattah',
          prodi: 'STr Informatics Engineering',
          foto: '/Member/WM_KHOIRUL.jpg',
        },
      ],
      Gen17: [
        {
          nama: 'Alfian Aditya K.',
          prodi: 'STr Game Technology',
          foto: '/Member/WM_ALFIAN.jpg',
        },
        {
          nama: 'Krisna Wahyu S.',
          prodi: 'STr Informatics Engineering',
          foto: '/Member/WM_KRISNA.jpg',
        },
        {
          nama: 'Syahrul Wicaksono',
          prodi: 'STr Telecommunications Engineering',
          foto: '/Member/WM_SYAHRUL.jpg',
        },
        {
          nama: 'Kurniawan Try Yudha',
          prodi: 'D3 Telecommunications Engineering',
          foto: '/Member/WM_YUDHA.jpg',
        },
      ],
      Gen18: [
        {
          nama: 'Kenzie Nararya',
          prodi: 'STr Computer Engineering',
          foto: '/Member/WM_Kenzie.jpg',
        },
        {
          nama: 'Akmal Luthfi',
          prodi: 'STr Applied Data Science',
          foto: '/Member/WM_Lutfi.jpg',
        },
        {
          nama: 'Hansdika Nugroho',
          prodi: 'STr Game Technology',
          foto: '/Member/WM_Hansdika.jpg',
        },
        {
          nama: 'M. Yaser Syafa A.',
          prodi: 'STr Game Technology',
          foto: '/Member/WM_Yaser.jpg',
        },
      ],
    },
    // ... other icon data
  ];

  const [showFloatingButton, setShowFloatingButton] = useState(false);

  const [selectedIcon, setSelectedIcon] = React.useState(0);
  // const [selectedIcon, setSelectedIcon] = useState(null);
  // const [selectedMembers, setSelectedMembers] = useState([]);

  const handleIconClick = (index) => {
    setSelectedIcon(index);
    // setSelectedMembers(memberData[IconData[index].label]);
  };

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 100; // Jarak scroll sebelum tombol muncul
      if (window.scrollY > threshold) {
        setShowFloatingButton(true);
      } else {
        setShowFloatingButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="bg-hero">
      {/* Section 1: tampilan utama ENT*/}
      <FormLayout>
        <section
          id="home"
          className="bg-hero flex flex-col pt-16 xl:pb-0 md:pt-0 relative"
        >
          <img
            src={process.env.PUBLIC_URL + '/chara.png'}
            className="w-full"
            alt="Hero"
          />
          <div className="absolute right-8 top-2 md:right-16 md:top-12 lg:right-40 lg:top-40">
            <p className="text-xl md:text-3xl lg:text-7xl lg:px-20 xl:px-0 font-montserrat font-extrabold font-p text-white pt-20">
              EEPIS NEWS
            </p>
            <p className="text-xl md:text-3xl lg:text-7xl lg:px-20 xl:px-0 font-montserrat font-extrabold font-p text-white">
              AND NETWORK
            </p>
            <p className="text-xl md:text-3xl lg:text-7xl lg:px-20 xl:px-0 font-montserrat font-extrabold font-p text-white md:mb-5">
              TEAM
            </p>
            <p className="hidden md:block text-[7px] md:text-sm lg:text-2xl font-p2 font-montserrat text-white md:mb-4">
              A Great Place to Grow Together with Our Team!
            </p>
            <div className="flex text-center flex-col gap-2 md:flex-row justify-center pt-1 md:pt-5 lg:pb-20 md:gap-5">
              {/* <Link
                to={"/requirement"}
                className="bg-teal-500 w-32 md:w-3/4 xl:w-1/2  text-white font-bold py-1 px-1 md:py-5 md:px-4 rounded-full text-[20px] md:text-4xl hover:bg-gray-300 hover:text-white transition duration-300 items-center text-center justify-center"
              >
                <button>Register</button>
              </Link> */}
              <a
                href="https://online.fliphtml5.com/ljlud/cgjj/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-700 w-32 md:w-3/4 xl:w-1/2  text-white font-bold py-1 px-1 md:py-5 md:px-4 rounded-full text-[20px] md:text-4xl hover:bg-gray-300 hover:text-white transition duration-300 items-center text-center justify-center"
              >
                CoreMagz
              </a>
            </div>
          </div>
        </section>

        {/* Section 2: Logo dan Penjelasan UKM */}
        <section
          id="about"
          className="bg-[#E6E3E1] py-4 px-2 md:py-40 md:px-8 grid md:grid-cols-2 grid-cols-none"
        >
          <div className="pt-5 lg:pt-10">
            <img
              src={process.env.PUBLIC_URL + '/logoent.png'}
              alt="Logo UKM XYZ"
              className="w-20 md:w-72 lg:w-96 items-center justify-center mx-auto mb-4"
            />
          </div>
          <div className="bg-white rounded-2xl p-2 shadow-xl">
            <p className="text-xs md:text-lg p-4 md:px-20 md:py-10 lg:text-2xl lg:px-20  font-montserrat font-semibold text-[#14477A] bg-[#F5FDFF] rounded-2xl">
              <span className="font-extrabold">ENT</span> is a Journalism and
              Networking Team responsible for managing the official Politeknik
              Elektronika Negeri Surabaya (PENS) media account, which is under
              the direct auspices of the Public Relations Secretariat.
            </p>
          </div>
        </section>

        {/* Section 3: Foto Divisi UKM */}
        <section id="division" className="text-center pt-20">
          <img
            src={process.env.PUBLIC_URL + 'division.png'}
            alt="Logo Divisi UKM XYZ"
            className="mx-auto w-full"
          />
        </section>

        {/* Section 4: Tombol Icon Kesamping */}
        <section className="bg-white pt-10 px-4 text-center">
          <div className="flex justify-center">
            {IconData.map((item, index) => (
              <div
                key={index}
                className={`transition-transform duration-300 transform cursor-pointer ${
                  selectedIcon === index
                    ? 'bg-white shadow-md ring-1 ring-gray-300'
                    : 'bg-white'
                } rounded-full flex items-center justify-center mx-4`}
                onClick={() => handleIconClick(index)}
              >
                <img
                  src={item.icon}
                  alt={item.label}
                  className="w-28 h-w-28 xl:w-44 xl:h-w-44"
                />
              </div>
            ))}
          </div>
        </section>

        {/* Section 5: Deskripsi Divisi */}
        <section className="bg-white px-10 lg:px-16 text-center">
          <div className="flex flex-col items-center">
            <div className="flex pt-8 pb-8">
              {['/Cha/ChaFG.svg', '/Cha/ChaDG.svg'].includes(
                IconData[selectedIcon]?.icon2,
              ) ? (
                <>
                  <div className="w-2/5 flex justify-center xl:justify-end">
                    <img
                      src={IconData[selectedIcon]?.icon2 || ''}
                      alt={IconData[selectedIcon]?.label || ''}
                      className="w-full h-w-full md:w-4/5 md:h-w-4/5 xl:w-3/5 xl:h-w-3/5 "
                    />
                  </div>
                  <div className="w-3/5 mt-7 text-left">
                    <p className="text-2xl  md:text-4xl lg:text-6xl font-GothamBlack font-black text-sky-900 mb-2">
                      {IconData[selectedIcon]?.label || ''}
                    </p>
                    <p className="text-xs md:text-lg lg:text-2xl lg:mr-10 xl:mr-28 text-gray-500 mb-4">
                      {IconData[selectedIcon]?.description || ''}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-3/5 mt-7 text-right pr-4">
                    <p className="text-2xl md:text-4xl lg:text-6xl font-GothamBlack font-black text-sky-900 mb-2">
                      {IconData[selectedIcon]?.label || ''}
                    </p>
                    <p className="text-xs md:text-lg lg:text-2xl lg:ml-10 xl:ml-28 text-gray-500 mb-4">
                      {IconData[selectedIcon]?.description || ''}
                    </p>
                  </div>
                  <div className="w-2/5 flex justify-center xl:justify-start">
                    <img
                      src={IconData[selectedIcon]?.icon2 || ''}
                      alt={IconData[selectedIcon]?.label || ''}
                      className="w-full h-w-full md:w-4/5 md:h-w-4/5 xl:w-3/5 xl:h-w-3/5"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </section>

        {/* Section 6: Daftar Member */}
        <section id="team" className="bg-white pb-8 px-10 md:px-14 text-center">
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-center ">
              <div className="mb-10 py-2 px-3 bg-teal-500 rounded-lg text-white">
                <p className="text-xs md:text-xl lg:text-3xl font-white">
                  Members!
                </p>
              </div>

              <div className="justify-center">
                {IconData[selectedIcon]?.Gen14?.length > 0 && (
                  <p className="text-2xl font-montserrat md:text-3xl lg:text-4xl text-sky-900 mb-4 mt-2">
                    14th Generation
                  </p>
                )}
                {IconData[selectedIcon]?.Gen14?.length > 0 ? (
                  <div className="flex flex-wrap justify-center">
                    {IconData[selectedIcon]?.Gen14.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center relative p-1"
                      >
                        <div className="relative">
                          <img
                            src={process.env.PUBLIC_URL + item.foto}
                            alt="Foto Member"
                            className="w-28 h-w-28 md:w-40 lg:w-44 rounded-md"
                          />
                          <div className="absolute bottom-0 left-0 w-full h-full font-sans bg-gradient-to-t from-gray-800 to-transparent text-white p-2 opacity-0 transition-opacity duration-300 hover:opacity-100 rounded-md flex justify-end flex-col">
                            <p className="text-name font-bold md:text-sm lg:text-base mb-1">
                              {item.nama}
                            </p>
                            <p className="text-prodi md:text-sm lg:text-sm">
                              {item.prodi}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>

              <div className="justify-center">
                {IconData[selectedIcon]?.Gen15?.length > 0 && (
                  <p className="text-2xl font-montserrat md:text-3xl lg:text-4xl text-sky-900 mb-4 mt-2">
                    15th Generation
                  </p>
                )}
                {IconData[selectedIcon]?.Gen15?.length > 0 ? (
                  <div className="flex flex-wrap justify-center">
                    {IconData[selectedIcon]?.Gen15.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center relative p-1"
                      >
                        <div className="relative">
                          <img
                            src={process.env.PUBLIC_URL + item.foto}
                            alt="Foto Member"
                            className="w-28 h-w-28 md:w-40 lg:w-44 rounded-md"
                          />
                          <div className="absolute bottom-0 left-0 w-full h-full font-sans bg-gradient-to-t from-gray-800 to-transparent text-white p-2 opacity-0 transition-opacity duration-300 hover:opacity-100 rounded-md flex justify-end flex-col">
                            <p className="text-name font-bold md:text-sm lg:text-base mb-1">
                              {item.nama}
                            </p>
                            <p className="text-prodi md:text-sm lg:text-sm">
                              {item.prodi}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>

              <div className="justify-center">
                {IconData[selectedIcon]?.Gen16?.length > 0 && (
                  <p className="text-2xl font-montserrat md:text-3xl lg:text-4xl text-sky-900 mb-4 mt-2">
                    16th Generation
                  </p>
                )}
                {IconData[selectedIcon]?.Gen16?.length > 0 ? (
                  <div className="flex flex-wrap justify-center">
                    {IconData[selectedIcon]?.Gen16.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center relative p-1"
                      >
                        <div className="relative">
                          <img
                            src={process.env.PUBLIC_URL + item.foto}
                            alt="Foto Member"
                            className="w-28 h-w-28 md:w-40 lg:w-44 rounded-md"
                          />
                          <div className="absolute bottom-0 left-0 w-full h-full font-sans bg-gradient-to-t from-gray-800 to-transparent text-white p-2 opacity-0 transition-opacity duration-300 hover:opacity-100 rounded-md flex justify-end flex-col">
                            <p className="text-name font-bold md:text-sm lg:text-base mb-1">
                              {item.nama}
                            </p>
                            <p className="text-prodi md:text-sm lg:text-sm">
                              {item.prodi}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>

              <div className="justify-center">
                {IconData[selectedIcon]?.Gen17?.length > 0 && (
                  <p className="text-2xl font-montserrat md:text-3xl lg:text-4xl text-sky-900 mb-4 mt-2">
                    17th Generation
                  </p>
                )}
                {IconData[selectedIcon]?.Gen17?.length > 0 ? (
                  <div className="flex flex-wrap justify-center">
                    {IconData[selectedIcon]?.Gen17.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center relative p-1"
                      >
                        <div className="relative">
                          <img
                            src={process.env.PUBLIC_URL + item.foto}
                            alt="Foto Member"
                            className="w-28 h-w-28 md:w-40 lg:w-44 rounded-md"
                          />
                          <div className="absolute bottom-0 left-0 w-full h-full font-sans bg-gradient-to-t from-gray-800 to-transparent text-white p-2 opacity-0 transition-opacity duration-300 hover:opacity-100 rounded-md flex justify-end flex-col">
                            <p className="text-name font-bold md:text-sm lg:text-base mb-1">
                              {item.nama}
                            </p>
                            <p className="text-prodi md:text-sm lg:text-sm">
                              {item.prodi}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="justify-center">
                {IconData[selectedIcon]?.Gen18?.length > 0 && (
                  <p className="text-2xl font-montserrat md:text-3xl lg:text-4xl text-sky-900 mb-4 mt-2">
                    18th Generation
                  </p>
                )}
                {IconData[selectedIcon]?.Gen18?.length > 0 ? (
                  <div className="flex flex-wrap justify-center">
                    {IconData[selectedIcon]?.Gen18.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center relative p-1"
                      >
                        <div className="relative">
                          <img
                            src={process.env.PUBLIC_URL + item.foto}
                            alt="Foto Member"
                            className="w-28 h-w-28 md:w-40 lg:w-44 rounded-md"
                          />
                          <div className="absolute bottom-0 left-0 w-full h-full font-sans bg-gradient-to-t from-gray-800 to-transparent text-white p-2 opacity-0 transition-opacity duration-300 hover:opacity-100 rounded-md flex justify-end flex-col">
                            <p className="text-name font-bold md:text-sm lg:text-base mb-1">
                              {item.nama}
                            </p>
                            <p className="text-prodi md:text-sm lg:text-sm">
                              {item.prodi}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </FormLayout>
      {showFloatingButton && (
        <button
          id="floatingButton"
          className="fixed bottom-4 right-4 bg-teal-500 text-white px-3 py-2 rounded-full shadow-md"
        >
          <a
            href="https://api.whatsapp.com/send?phone=6285708828302" // Replace with your WhatsApp number
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </button>
      )}
    </div>
  );
};

export default LandingPage;
